import { Injectable } from '@angular/core';
import { BaselineTemplateParam } from '@model';
import { ValidatorFn, Validators } from '@angular/forms';

@Injectable()
export class BaselineValidationsService {
  param: BaselineTemplateParam;
  validations: ValidatorFn[] = [];

  constructor(param: BaselineTemplateParam) {
    this.param = param;
    this.setValidations();
  }

  setValidations(): void {
    if (this.param.validations) {
      for (const validation of this.param.validations) {
        if (validation.operator === 'min') {
          this.validations.push(Validators.min(validation.value));
        }

        if (validation.operator === 'max') {
          this.validations.push(Validators.max(validation.value));
        }
      }
    }
  }

  getValidations(): ValidatorFn[] {
    return this.validations;
  }
}
