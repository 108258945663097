import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaselineDefinitionsService } from '../shared/services/baseline-definitions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaselineDefinition } from '@model';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements OnInit, OnDestroy {
  loading = true;
  noBaselineDefinitions = false;
  subscriptions = [];
  baselineDefinition: BaselineDefinition;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private baselineDefinitionService: BaselineDefinitionsService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.baselineDefinitionService.baselineDefinitions$.subscribe(baselineDefinitions => {
        this.navigateToFirstBaselineDefinition(baselineDefinitions);
      }),
      this.route.data.subscribe(data => {
        this.navigateToFirstBaselineDefinition(data.data);
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  navigateToFirstBaselineDefinition(baselineDefinitions: BaselineDefinition[]): void {
    if (baselineDefinitions?.length && !this.router.isActive('/create', false)) {
      this.router.navigate([`details/${baselineDefinitions[0].id}/view-baseline-definition`], {});
    } else {
      this.loading = false;
      this.noBaselineDefinitions = true;
    }
  }

  routeToCreatePage(): void {
    this.router.navigate([`create`], {});
  }
}
