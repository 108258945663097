import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaselineDefinitionsService } from '../../shared/services/baseline-definitions.service';
import { BaselineDefinition } from '@model';
import { LoadingService } from '../../shared/services/loading.service';

export interface BaselineData {
  id: string;
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  dialogState = 'DEFAULT';

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    private baselineService: BaselineDefinitionsService,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: BaselineData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  async handleDelete() {
    try {
      this.dialogState = 'DELETING';
      this.baselineService
        .deleteBaselineDefinition$(this.data.id)
        .subscribe((baselineDefinition: BaselineDefinition) => {
          this.dialogState = 'DELETE_SUCCESS';
          this.baselineService.getBaselineDefinitions$();
        });

      setTimeout(() => {
        this.dialogRef.close();
        this.loadingService.setPageLoading(true);
        this.router.navigate([`/`], {});
      }, 2000);
    } catch (e) {
      this.dialogState = 'DELETE_FAILED';
      setTimeout(() => {
        this.dialogRef.close();
      }, 2000);
    }
  }
}
