import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiService } from './api.service';
import * as convertKeys from 'convert-keys';

@Injectable()
export class TypesService {
  PAGE_SIZE = 1000;
  MAX_SIMULTANEOUS_REQUESTS = 10;
  $AllPagesForType = new BehaviorSubject(null);
  constructor(private apiService: ApiService) {}

  async getAllPagesForType(type: string) {
    const firstPage = await this.getPageForType(type);
    const { meta } = firstPage;
    let { data } = firstPage;
    let numPages = 1;
    let pagePromises = [];

    // calculate the number of pages we will call for
    if (meta && meta.hasOwnProperty(`total`)) {
      numPages = Math.ceil(meta.total / this.PAGE_SIZE);
    }

    if (numPages === 1) {
      data = data
        .map((obj: any) => convertKeys.toCamel(obj))
        .sort((a: any, b: any) => a.displayLabel.localeCompare(b.displayLabel));
      return data;
    }

    // call for all pages past the 1st page (we already fetched the 1st page)
    // in blocks of maximum 10 simultaneous requests (to avoid errors for timeouts)
    let pageResults: any[] = [];
    let pagesBlock = this.MAX_SIMULTANEOUS_REQUESTS;
    for (let page = 2; page <= numPages; page++) {
      pagePromises.push(this.getPageForType(type, page));
      if (page > pagesBlock) {
        pagesBlock += this.MAX_SIMULTANEOUS_REQUESTS;
        const additionalPages = await Promise.all(pagePromises);
        pageResults = [...pageResults, ...additionalPages];
        pagePromises = [];
      }
    }

    const leftOverPages = await Promise.all(pagePromises);
    pageResults = [firstPage, ...pageResults, ...leftOverPages];

    // break the pages apart and concatenate back into one list
    const results = pageResults.reduce((flattenedResults: any[], result: any) => {
      flattenedResults.push(...result.data);
      return convertKeys.toCamel(flattenedResults);
    }, []);

    results.sort((a: any, b: any) => a.displayLabel?.localeCompare(b.displayLabel));

    this.$AllPagesForType.next(results);
    return results;
  }

  async getPageForType(type: string, page = 1) {
    return await this.apiService.getRaw(`types/${type}?page=${page}`);
  }
}
