import { BaselineTemplate } from './baseline-template';
import * as convertKeys from 'convert-keys';

export interface BaselineDefinition {
  blDefinitionId: string;
  baselineType: string;
  granularity: number;
  algorithm: string;
  pointType: string;
  assetType: string;
  product: string;
  blTemplateName: string;
  id: string;
  lastUpdated: string;
  productId: string;
  operator: string;
  program: string;
  status: string;
  defaultInd: boolean;
  adjustmentType: string;
  exclusionSetIds: string[];
  displayLabel: string;
  drType?: string;
}

export class DefaultBaselineDefinitions {
  baselineDefinition: Partial<BaselineDefinition> = {};
  constructor(baselineDefinition: Partial<BaselineDefinition>, template: BaselineTemplate) {
    this.baselineDefinition = baselineDefinition;
    this.baselineDefinition.status = 'ACTIVE';
    this.baselineDefinition.defaultInd = false;

    const { templateParams } = template;

    for (const param of templateParams) {
      if (param.type === 'boolean') {
        this.baselineDefinition[param.name] = baselineDefinition[param.name] || JSON.parse(param.defaultValue);
      } else {
        this.baselineDefinition[param.name] = baselineDefinition[param.name] || param.defaultValue;
      }
    }

    this.baselineDefinition = convertKeys.toCamel(this.baselineDefinition);
  }
}
