<form #baselineDef="ngForm" id="baselineForm" [formGroup]="baselineForm">
    <div class="w-full flex justify-between title-heading">
        <div class="flex flex-1 justify-start items-center justify-self-start margin-right-auto icon-header">
            <div class="icon-container">
                <mat-icon class="header-icon">widgets</mat-icon>
            </div>
            <div class="title-wrapper">
                <h1>{{ 'baseline.create.page_title' | translate }}</h1>
                <span>{{ 'baseline.create.subtitle' | translate }}</span>
            </div>
        </div>
            <button
                class="x-btn-secondary dialog-secondary"
                color="gray"
                aria-label="Cancel"
                (click)="handleCancel()"
                data-automation="dialogCancel"
            >
                <span> {{ 'baseline.button.cancel' | translate }}</span>
            </button>
            <button
                class="icon-btn submit-btn x-btn-primary"
                [disabled]="baselineForm.invalid"
                (click)="createBaselineDefinition()"
                data-automation="submitBtn"
            >
                <div *ngIf="submitting" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text">{{ 'baseline.button.submit' | translate }}</span>
            </button>
    </div>
    <section class="flex flex-wrap form-group">
        <div class="form-field-wrapper">
            <div>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color">{{ 'baseline.operator' | translate }}:</mat-label>
                    <mat-select
                        formControlName="operator"
                        ngDefaultControl
                        data-automation="operator"
                        placeholder="{{ 'baseline.create.placeholder.operator' | translate }}"
                    >
                        <ng-container *ngFor="let operator of operators">
                            <mat-option [value]="operator">{{ operator.displayLabel }}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="form-field-wrapper">
            <div>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color">{{ 'baseline.program' | translate }}:</mat-label>
                    <mat-select
                        formControlName="program"
                        ngDefaultControl
                        data-automation="program"
                        placeholder="{{ 'baseline.create.placeholder.program' | translate }}"
                    >
                        <ng-container *ngFor="let program of filteredPrograms">
                            <mat-option [value]="program">{{ program.displayLabel }}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="form-field-wrapper">
            <div>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label class="text-light-color">{{ 'baseline.product' | translate }}:</mat-label>
                    <mat-select
                        formControlName="product"
                        ngDefaultControl
                        data-automation="product"
                        placeholder="{{ 'baseline.create.placeholder.product' | translate }}"
                    >
                        <ng-container *ngFor="let product of filteredProducts">
                            <mat-option [value]="product">{{ product.displayLabel }}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="form-field-wrapper">
            <div>
                <lib-ngx-deeplinker [config]="marketDeeplinkerConfig" id="product_deeplink"></lib-ngx-deeplinker>
            </div>
        </div>
        <div class="form-field-wrapper">
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label class="text-light-color">{{ 'baseline.bl_template_name' | translate }}:</mat-label>
                <mat-select
                    formControlName="blTemplateName"
                    ngDefaultControl
                    data-automation="templateName"
                    placeholder="{{ 'baseline.create.placeholder.baseline_template_name' | translate }}"
                >
                    <ng-container *ngFor="let baselineTemplate of baselineTemplates">
                        <mat-option [value]="baselineTemplate.templateName">{{
                            baselineTemplate.templateName
                        }}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-field-wrapper">
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label class="text-light-color">{{ 'baseline.definition_label' | translate }}:</mat-label>
                <input
                    id="definitionLabel"
                    matInput
                    placeholder="{{ 'baseline.create.placeholder.definition_label' | translate }}"
                    formControlName="definitionLabel"
                    data-automation="definitionLabel"
                    data-url-persist
                />
                <mat-error
                    *ngIf="
                        baselineDefinitionLabelField().invalid &&
                        (baselineDefinitionLabelField().dirty || baselineDefinitionLabelField().touched)
                    "
                    class="alert alert-danger"
                >
                    <mat-error *ngIf="baselineDefinitionLabelField().errors.maxlength">
                        <span data-automation="baseline-definition-label-maxlength-error">
                            {{ 'baseline.validation.maxlength' | translate }}
                        </span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>
    </section>
</form>
