import { Injectable } from '@angular/core';
import { from, Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { Operator, Product, Program } from '@model';
import { ResourceService } from '../../services/resource.service';
import { TypesService } from './types.service';
import { catchError } from 'rxjs/operators';
import { GlobalAlertService } from './global-alert.service';

@Injectable()
export class ProductService {
  readonly products$ = new Subject<Product[]>();

  constructor(
    private apiService: ApiService,
    private alertService: GlobalAlertService,
    private resourceService: ResourceService<Program | Operator>,
    private typesService: TypesService,
  ) {}

  getProducts$(): Observable<Product[]> {
    const observingProducts = from(this.typesService.getAllPagesForType('product')).pipe(
      catchError(err => {
        this.alertService.setError(err, 7000);
        throw err;
      }),
    );
    observingProducts.subscribe(products => this.products$.next(products));
    return observingProducts;
  }
}
