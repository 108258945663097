<form #baselineDef="ngForm" id="baselineForm">
    <div class="w-full flex justify-between title-heading">
        <div class="flex justify-start items-center justify-self-start margin-right-auto icon-header">
            <div class="icon-container">
                <mat-icon class="header-icon">widgets</mat-icon>
            </div>
            <div class="title-wrapper">
                <h1>
                    {{ baselineDefinition.operator }} >> {{ baselineDefinition.program }} >>
                    {{ baselineDefinition.product }}
                </h1>
                <span>{{ 'baseline.subtitle' | translate }}</span>
            </div>
        </div>
        <div class="right-actions">
            <button
                type="button"
                data-automation="delete-btn"
                class="icon-btn x-btn-primary"
                [disabled]="!userService.hasPermission(APPNAME, 'DELETE')"
                (click)="openDeleteDialog()"
            >
                <span class="btn-text">{{ 'baseline.button.delete' | translate }}</span>
            </button>
            <span class="text-warn last-save"
                >{{ 'baseline.last_saved' | translate }}: {{ baselineDefinition.lastUpdated | date : 'medium' }}</span
            >
        </div>
    </div>
    <section class="flex flex-wrap form-group">
        <div class="form-field-wrapper">
            <span class="text-light-color">{{ 'baseline.id' | translate }}:</span>
            <div class="field-value">{{ baselineDefinition.id }}</div>
        </div>
        <div class="form-field-wrapper">
            <span class="text-light-color">{{ 'baseline.operator' | translate }}:</span>
            <div class="field-value">{{ baselineDefinition.operator }}</div>
        </div>
        <div class="form-field-wrapper">
            <span class="text-light-color">{{ 'baseline.program' | translate }}:</span>
            <div class="field-value">{{ baselineDefinition.program }}</div>
        </div>
        <div class="form-field-wrapper">
            <span class="text-light-color">{{ 'baseline.product' | translate }}:</span>
            <div class="field-value">{{ baselineDefinition.product }}</div>
        </div>
        <div class="form-field-wrapper">
            <span class="text-light-color">{{ 'baseline.bl_template_name' | translate }}:</span>
            <div class="field-value">{{ baselineDefinition.blTemplateName }}</div>
        </div>
        <div class="form-field-wrapper">
            <span class="text-light-color">{{ 'baseline.definition_label' | translate }}:</span>
            <app-inline-edit
                ngDefaultControl
                htmlTag="span"
                [canEdit]="userService.hasPermission(APPNAME, 'UPDATE')"
                [inputType]="'input'"
                [(formControl)]="baselineDefinitionLabelControl"
                [label]="'Baseline Definition Label'"
                [fieldToUpdate]="'displayLabel'"
                [validations]="Validators.maxLength(60)"
                [required]="false"
                [callback]="handleEdit"
            >
            </app-inline-edit>
            <mat-spinner
                *ngIf="loadingBaselineDefinitions && loadingBaselineDefinitions['displayLabel']"
                matSuffix
                diameter="30"
                color="warn"
            ></mat-spinner>
        </div>
        <div class="form-field-wrapper">
            <span class="text-light-color">{{ 'baseline.exclusions' | translate }}:</span>
            <mat-select
                data-automation="exclusionBtn"
                multiple
                [formControl]="baselineDefinitionExclusionControl"
                (selectionChange)="handleExclusionChange(baselineDefinitionExclusionControl)"
                placeholder="{{ 'baseline.create.placeholder.exclusions' | translate }}"
            >
                <ng-container *ngFor="let exclusion of templateExclusionSets">
                    <mat-option [value]="exclusion.id">{{ exclusion.description }}</mat-option>
                </ng-container>
            </mat-select>
            <mat-spinner
                *ngIf="loadingBaselineDefinitions && loadingBaselineDefinitions['exclusionSetIds']"
                matSuffix
                diameter="30"
                color="warn"
            ></mat-spinner>
        </div>
        <div class="form-field-wrapper pill-wrapper">
            <ul>
                <ng-container *ngFor="let exclusion of baselineDefinition.exclusionSetIds">
                    <li class="pill">{{ getExclusionLabel(exclusion) }}</li>
                </ng-container>
            </ul>
        </div>
        <div class="form-field-wrapper">
            <div class="flex items-start">
                <span class="text-light-color">{{ 'baseline.default' | translate }}:</span>
                <mat-icon
                    matTooltip="{{ 'baseline.tooltip.default' | translate }}"
                    matTooltipPosition="right"
                    [color]="'primary'"
                    class="tooltip-icon"
                    matTooltipClass="'tooltip'"
                >
                    info_outline
                </mat-icon>
            </div>
            <div class="field-value">
                <mat-checkbox
                    id="default-checkbox"
                    [formControl]="defaultIndControl"
                    (change)="handleDefaultCheckboxChange(defaultIndControl)"
                    [disabled]="isDefault()"
                    data-automation="default-checkbox"
                ></mat-checkbox>
            </div>
        </div>
    </section>
    <br />
    <h2>{{ 'baseline.variables' | translate }}</h2>
    <hr />
    <section class="form-group" *ngIf="baselineTemplateVariables">
        <ng-container *ngFor="let section of baselineTemplateSectionKeys">
            <br />
            <h2>{{ section }}</h2>
            <br />
            <section class="flex flex-wrap">
                <ng-container *ngFor="let baselineTemplateVariable of baselineTemplateVariables[section]">
                    <div class="form-field-wrapper">
                        <div class="flex items-start">
                            <span class="text-light-color">{{ baselineTemplateVariable.label | translate }}:</span>
                            <mat-icon
                                *ngIf="baselineTemplateVariable.description"
                                matTooltip="{{ baselineTemplateVariable.description }}"
                                matTooltipPosition="right"
                                [color]="'primary'"
                                class="tooltip-icon"
                                matTooltipClass="'tooltip'"
                                aria-tooltip="{{ baselineTemplateVariable.description }}"
                            >
                                info_outline
                            </mat-icon>
                        </div>
                        <div class="flex">
                            <app-inline-edit
                                ngDefaultControl
                                htmlTag="span"
                                [canEdit]="userService.hasPermission(APPNAME, 'UPDATE')"
                                [inputType]="baselineTemplateVariable.inputType"
                                [selectDisplayField]="baselineTemplateVariable.selectDisplayField"
                                [selectArray]="baselineTemplateVariable.selectArray"
                                [selectValueField]="baselineTemplateVariable.selectValueField"
                                [selectIdField]="baselineTemplateVariable.selectIdField"
                                [(formControl)]="baselineTemplateVariable.formControl"
                                [label]="baselineTemplateVariable.label"
                                [fieldToUpdate]="baselineTemplateVariable.name"
                                [validations]="baselineTemplateVariable.validations"
                                [required]="baselineTemplateVariable.required"
                                [callback]="handleEdit"
                            >
                            </app-inline-edit>
                            <mat-spinner
                                id="loading-spinner"
                                *ngIf="
                                    loadingBaselineDefinitions &&
                                    loadingBaselineDefinitions[baselineTemplateVariable.name]
                                "
                                matSuffix
                                diameter="30"
                                color="warn"
                            ></mat-spinner>
                        </div>
                    </div>
                </ng-container>
            </section>
        </ng-container>
    </section>
</form>
