<div class="flex justify-start items-center" *ngIf="!isEditing">
    <mat-checkbox *ngIf="isBoolean()" [formControl]="formControl" (change)="editingEndCheckbox()"></mat-checkbox>

    <div class="edit-wrapper flex" *ngIf="!isBoolean()">
        <div [innerHTML]="innerHtml"></div>
        <mat-icon
            *ngIf="canEdit"
            [attr.data-automation]="'edit-' + fieldToUpdate"
            class="icon"
            (click)="isEditing = !isEditing"
            >edit</mat-icon
        >
    </div>
</div>

<div *ngIf="isEditing">
    <mat-form-field [ngSwitch]="inputType" appearance="outline">
        <input
            autofocus
            matInput
            *ngSwitchCase="'input'"
            [formControl]="formControl"
            (focusout)="editingEnd(formControl.value)"
            (keyup.enter)="editingEnd(formControl.value)"
            (keyup.escape)="escWithoutSaving()"
            placeholder="Enter {{ label }}"
            [attr.data-automation]="fieldToUpdate"
            required="{{ !!required }}"
        />

        <input
            autofocus
            matInput
            *ngSwitchCase="'number'"
            [formControl]="formControl"
            (focusout)="editingEnd(formControl.value)"
            (keyup.enter)="editingEnd(formControl.value)"
            (keyup.escape)="escWithoutSaving()"
            placeholder="Enter {{ label }}"
            [attr.data-automation]="fieldToUpdate"
            required="{{ !!required }}"
        />

        <mat-select
            *ngSwitchCase="'select'"
            [formControl]="formControl"
            (selectionChange)="editingEnd(selectDisplayValue($event.value))"
            (keyup.escape)="escWithoutSaving()"
            placeholder="Choose {{ label }}"
            required="{{ !!required }}"
            [attr.data-automation]="fieldToUpdate"
        >
            <mat-option [value]="null" *ngIf="!required">{{ 'baseline.choose' | translate }}</mat-option>
            <mat-option
                *ngFor="let value of selectArray"
                [value]="value[selectValueField]"
                id="{{ 'timezone-' + value[selectIdField] }}"
            >
                {{ value[selectDisplayField] }}
            </mat-option>
        </mat-select>

        <mat-error *ngIf="formControl.hasError('required')">
            {{ 'baseline.validation.required' | translate }}
        </mat-error>

        <mat-error *ngIf="formControl.hasError('min')">
            {{ 'baseline.validation.min' | translate }}
            <span *ngIf="hasValidations()">{{ getValidation('min') }}</span>
        </mat-error>

        <mat-error *ngIf="formControl.hasError('max')">
            {{ 'baseline.validation.max' | translate }}
            <span *ngIf="hasValidations()">{{ getValidation('max') }}</span>
        </mat-error>

        <mat-error *ngIf="formControl.hasError('maxlength')">
            {{ 'baseline.validation.maxlength' | translate }}
        </mat-error>
    </mat-form-field>
</div>
