import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoadingService {
  pageLoading$: Subject<boolean>;

  constructor() {
    this.pageLoading$ = new Subject<boolean>();
  }

  setPageLoading(loading: boolean) {
    this.pageLoading$.next(loading);
  }
}
