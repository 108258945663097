import { Injectable } from '@angular/core';
import { BaselineTemplate } from '@model';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ResourceService } from '../../services/resource.service';

@Injectable()
export class BaselineTemplatesService {
  constructor(private apiService: ApiService, private resourceService: ResourceService<BaselineTemplate>) {}

  getBaselineTemplates$(): Observable<BaselineTemplate[]> {
    return this.resourceService.getList(`baseline-templates`);
  }
}
