import { Injectable } from '@angular/core';
import { from, Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { Operator } from '@model';
import { catchError, tap } from 'rxjs/operators';
import { ResourceService } from '../../services/resource.service';
import { TypesService } from './types.service';
import { GlobalAlertService } from './global-alert.service';

@Injectable()
export class OperatorService {
  private readonly operatorEmitter = new Subject<Operator>();
  readonly operators$ = new Subject<Operator[]>();

  constructor(
    private apiService: ApiService,
    private resourceService: ResourceService<Operator>,
    private typesService: TypesService,
    private alertService: GlobalAlertService,
  ) {}

  getOperator$(operatorId: number | string): Observable<Operator> {
    return this.resourceService
      .getById('operators', operatorId)
      .pipe(tap(operator => this.operatorEmitter.next(operator)));
  }

  getOperators$(): Observable<Operator[]> {
    const observingOperators = from(this.typesService.getAllPagesForType('operator')).pipe(
      catchError(err => {
        this.alertService.setError(err, 7000);
        throw err;
      }),
    );
    observingOperators.subscribe(operators => this.operators$.next(operators));
    return observingOperators;
  }
}
