import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { BaselineDefinition, BaselineTemplate } from '@model';
import { BaselineDefinitionsService } from '../shared/services/baseline-definitions.service';
import { BaselineTemplatesService } from '../shared/services/baseline-templates.service';

@Injectable()
export class RootResolver implements Resolve<BaselineDefinition[]> {
  constructor(private baselineDefinitionService: BaselineDefinitionsService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<BaselineDefinition[]> {
    return this.baselineDefinitionService.getBaselineDefinitions$();
  }
}
