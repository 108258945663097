<div class="spinner-container" *ngIf="loading">
    <mat-spinner mode="indeterminate" diameter="60"></mat-spinner>
</div>

<enel-tree-display *ngIf="!loading"
        [treeData]="dataSource"
        [labelIdentifier]="labelIdentifier"
        [typeIdentifier]="typeIdentifier"
        [iconIdentifier]="typeIdentifier"
        [iconDefinitions]="ICON_DEFINITION"
        [selectedNodes]="selectedNodes"
        [disabledNodeDefinitions]="disabledNodeDefinitions"
        [hideDisabledCheckbox]="true"
        [showControls]="true"
        [showSearch]="true"
        [checkbox]="true"
        [hideIrrelevant]="true"
        (selection$)="onSelect($event)"
        [select]="selectedNode"
        data-automation="locationTreeSelector"
>
</enel-tree-display>

<ng-container *ngIf="!loading">
    <div *ngIf="noBaselines" class="list-empty">
        {{ 'baseline.list.none_configured' | translate }}
    </div>
</ng-container>

<div class="create-button-container">
    <button
        [disabled]="!userService.hasPermission('MANAGE_BASELINES', 'CREATE')"
        (click)="routeToCreatePage()"
        id="create-button"
        class="icon-btn x-btn-primary"
        color="accent"
    >
        <mat-icon>add</mat-icon>
        <span class="btn-text">{{ 'baseline.list.button.create_baseline' | translate }}</span>
    </button>
    <button
        (click)="createExclusion()"
        id="create-exclusion"
        class="icon-btn x-btn-primary"
        color="accent"
        disabled="true"
    >
        <mat-icon>add</mat-icon>
        <span class="btn-text">{{ 'baseline.list.button.create_exclusion' | translate }}</span>
    </button>
</div>
