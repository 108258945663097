import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { BaselineTemplate, Operator, Product, Program } from '@model';
import { BaselineTemplatesService } from '../shared/services/baseline-templates.service';
import { ProductService } from '../shared/services/product.service';
import { ProgramsService } from '../shared/services/programs.service';
import { OperatorService } from '../shared/services/operators.service';

@Injectable()
export class CreateBaselineResolver implements Resolve<[BaselineTemplate[], Product[], Program[], Operator[]]> {
  constructor(
    private baselineTemplateService: BaselineTemplatesService,
    private productService: ProductService,
    private programsService: ProgramsService,
    private operatorsService: OperatorService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<[BaselineTemplate[], Product[], Program[], Operator[]]> {
    const templates$ = this.baselineTemplateService.getBaselineTemplates$();
    const products$ = this.productService.getProducts$();
    const programs$ = this.programsService.getPrograms$();
    const operators$ = this.operatorsService.getOperators$();
    return forkJoin([templates$, products$, programs$, operators$]);
  }
}
