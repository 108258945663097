import { Component, OnInit, NgZone } from '@angular/core';
import { BaselineDefinition, BaselineTemplate, DefaultBaselineDefinitions, Operator, Product, Program } from '@model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BaselineDefinitionsService } from '../shared/services/baseline-definitions.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../shared/services/loading.service';
import { DeeplinksService } from '../shared/services/deeplinks.service';
@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {
  CREATE_NEW_PRODUCT = 'Create New Product';
  deeplinkerData: any = {};
  marketDeeplinkerConfig: any = {};

  namePlaceholder = '';
  nameLabel = '';
  userLocale = 'en_US';
  subscriptions = [];
  baselineDefinition: Partial<BaselineDefinition> = {
    product: '',
    blTemplateName: 'x_of_y',
  };
  baselineTemplates: BaselineTemplate[];
  products: Product[] = [];
  programs: Program[] = [];
  filteredPrograms: Program[] = [];
  filteredProducts: Product[] = [];
  operators: Operator[] = [];
  product;
  operator;
  productId;
  displayLabel;
  program;
  blTemplateName;
  baselineForm = new UntypedFormGroup({
    product: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    program: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    operator: new UntypedFormControl('', Validators.required),
    blTemplateName: new UntypedFormControl('', Validators.required),
    definitionLabel: new UntypedFormControl('', Validators.maxLength(60)),
  });
  submitting = false;

  // TODO: Support more template types
  supportedTemplates = ['x_of_y', 'avbl', 'meter_before', 'fsl'];
  selectedId: any;

  constructor(
    private baselineDefinitionsService: BaselineDefinitionsService,
    private deeplinksInternalService: DeeplinksService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private baselineService: BaselineDefinitionsService,
    private loadingService: LoadingService,
  ) {
    this.selectedId = this.route?.snapshot?.params?.selectedId;
    this.nameLabel = this.translateService.instant('baseline.baseline_name');
    this.namePlaceholder = this.translateService.instant('baseline.placeholder.baseline_name');
    this.CREATE_NEW_PRODUCT = this.translateService.instant('baseline.button.create_product');
    this.setDeeplinkConfigs();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.data.subscribe((resolved) => {
        this.loadingService.setPageLoading(false);
        // TODO: Remove filter when we support all template types
        this.baselineTemplates = resolved.data[0].filter((template) =>
          this.supportedTemplates.includes(template.templateName),
        );
        this.products = resolved.data[1];
        this.programs = resolved.data[2];
        this.operators = resolved.data[3];
      }),
      this.deeplinksInternalService.deeplink$.subscribe(async (deeplinkUrl) => {
        if (deeplinkUrl) {
          this.marketDeeplinkerConfig.destinationUrl = deeplinkUrl;
        }
      }),
    );

    this.baselineForm.get('operator').valueChanges.subscribe((operator) => {
      this.baselineDefinition.operator = operator.displayLabel;
      this.operator = operator.displayLabel;
      this.baselineForm.get('program').enable();
      this.filteredPrograms = this.programs.filter((program) => program.operatorId === operator.id);
    });

    this.baselineForm.get('program').valueChanges.subscribe((program) => {
      this.baselineDefinition.program = program.displayLabel;
      this.program = program.displayLabel;
      this.baselineForm.get('product').enable();
      this.filteredProducts = this.products.filter((product) => product.programId === program.id);
    });

    this.baselineForm.get('product').valueChanges.subscribe((product) => {
      this.baselineDefinition.productId = product.id;
      this.baselineDefinition.product = product.displayLabel;
      this.productId = product.id;
      this.product = product.displayLabel;
    });

    this.baselineForm.get('blTemplateName').valueChanges.subscribe((val) => {
      this.baselineDefinition.blTemplateName = val;
      this.blTemplateName = val;
      this.updateVariables(val);
    });

    this.baselineForm.get('definitionLabel').valueChanges.subscribe((val) => {
      this.baselineDefinition.displayLabel = val;
      this.displayLabel = val;
    });

    this.deeplinksInternalService.setMarketAdminUrl();
  }

  baselineDefinitionLabelField() {
    return this.baselineForm.get('definitionLabel');
  }

  resetBaseline() {
    this.baselineDefinition = {
      product: '',
    };
    this.baselineDefinition.operator = this.operator;
    this.baselineDefinition.program = this.program;
    this.baselineDefinition.product = this.product;
    this.baselineDefinition.productId = this.productId;
    this.baselineDefinition.displayLabel = this.displayLabel;
    this.baselineDefinition.blTemplateName = this.blTemplateName;
  }

  updateVariables(templateName) {
    this.resetBaseline();
    const template = this.baselineTemplates?.find((template) => template.templateName === templateName);
    const { baselineDefinition } = new DefaultBaselineDefinitions(this.baselineDefinition, template);
    this.baselineDefinition = baselineDefinition;
  }

  createBaselineDefinition() {
    // Default display label to template name
    if (!this.baselineDefinition.displayLabel) {
      this.baselineDefinition.displayLabel = this.baselineDefinition.blTemplateName;
    }
    this.submitting = true;
    this.loadingService.setPageLoading(true);
    this.baselineService.createBaselineDefinition$(this.baselineDefinition).subscribe((baseline) =>
      this.ngZone.run(() => {
        this.submitting = false;
        this.baselineDefinitionsService.getBaselineDefinitions$();
        this.router.navigateByUrl(`/details/${baseline.id}/view-baseline-definition`);
      }),
    );
  }

  private setDeeplinkConfigs() {
    this.marketDeeplinkerConfig = {
      appPrefix: 'bsl',
      destinationUrl: '',
      idToPopulate: 'product',
      displayLabel: this.CREATE_NEW_PRODUCT,
    };
  }

  handleCancel(): void {
    this.loadingService.setPageLoading(true);
    if (this.selectedId) {
      this.router.navigate([`details/${this.selectedId}/view-baseline-definition`], {});
    } else {
      this.router.navigate(['/']);
    }
  }

}
