import { Injectable } from '@angular/core';
import {combineLatestWith, from, merge, Observable, Subject} from 'rxjs';
import { ApiService } from './api.service';
import { BaselineDefinition } from '@model';
import { catchError, map, tap, timeout } from 'rxjs/operators';
import { ResourceService } from '../../services/resource.service';
import { GlobalAlertService } from './global-alert.service';
import { LoadingService } from './loading.service';
import { AtlasPagesService, FilterEntity } from 'atlas-pages-loader';
import {CookieService} from "./cookie.service";
import {HttpClient} from "@angular/common/http";
import {TreeNode} from "enel-tree";

const BASELINE_HIERARCHY = ['operator', 'program','product'];

@Injectable()
export class BaselineDefinitionsService {
  conflictError = new Subject<any[]>();
  readonly baselineDefinitions$ = new Subject<BaselineDefinition[]>();
  readonly baselineHierarchy$ = new Subject<any[]>();
  readonly selectedBaselineDefinition = new Subject<string>();

  private readonly baselineDefinitionEmitter = new Subject<BaselineDefinition>();

    hierarchyService: AtlasPagesService;
    cookieService: CookieService;
    flattenedHierarchy = [];

  constructor(
    private apiService: ApiService,
    private resourceService: ResourceService<BaselineDefinition>,
    private alertService: GlobalAlertService,
    private loadingService: LoadingService,
    private httpClient: HttpClient,
    cookieService: CookieService
  ) {
      this.cookieService = cookieService;
      this.hierarchyService = new AtlasPagesService(
          this.cookieService.getMarketsMock(),
          this.cookieService.getEnocSession(),
          this.httpClient,
      );
  }

  getBaselineDefinitions$(productId?: string): Observable<BaselineDefinition[]> {
    let observingBaselineDefinitions: Observable<BaselineDefinition[]>;
    if (productId) {
      observingBaselineDefinitions = this.resourceService.getList(`baseline-definitions?product_id=${productId}`);
    } else {
      observingBaselineDefinitions = this.resourceService.getList(`baseline-definitions`);
    }

    const baselineHierarchy = from(this.hierarchyService.getSpecificHierarchies(BASELINE_HIERARCHY));

    observingBaselineDefinitions.pipe(combineLatestWith(baselineHierarchy)).subscribe(([baselines, hierarchy]) => {

        baselines.sort((a, b) => {
            return `${a.displayLabel}`.localeCompare(`${b.displayLabel}`);
        });

        baselines.map((baseline: BaselineDefinition) => {
            baseline.drType = 'Baseline'
            return baseline;
        });
        const copyOfHierarchy = JSON.parse(JSON.stringify(hierarchy));
        const toSearch = [...copyOfHierarchy];
        const products = [];

        for(const node of toSearch) {
            if(node.children) {
                toSearch.push(...node.children);
            }
            else if(node.drType == "Product") {
                products.push(node);
            }
        }
        products.forEach(product => {
            const children = baselines.filter(baseline => baseline.productId === product.id);
            if(children) {
                product.children = children;
            }
        });
        copyOfHierarchy.map(node => {
            return this.seedProducts(node, products);
        });

        this.baselineHierarchy$.next(copyOfHierarchy);
      });

    observingBaselineDefinitions.subscribe((baselineDefinitions) =>
      this.baselineDefinitions$.next(baselineDefinitions),
    );
    return observingBaselineDefinitions;
  }

  getBaselineDefinition$(baselineDefinitionId: number | string): Observable<BaselineDefinition> {
    return this.resourceService.getById('baseline-definitions', baselineDefinitionId).pipe(
      tap((baselineDefinition) => this.baselineDefinitionEmitter.next(baselineDefinition)),
      tap((baselineDefinition) => this.selectedBaselineDefinition.next(baselineDefinition.id)),
    );
  }

  createBaselineDefinition$(
    baselineDefinition: BaselineDefinition | Partial<BaselineDefinition>,
  ): Observable<BaselineDefinition> {
    return this.resourceService.add(`baseline-definitions`, baselineDefinition).pipe(
      tap((baselineDefinition) => this.selectedBaselineDefinition.next(baselineDefinition.id)),
      catchError((err) => {
        this.alertService.setError(err, 7000);
        this.loadingService.setPageLoading(false);
        this.selectedBaselineDefinition.next(null);
        throw err;
      }),
    );
  }

  updateBaselineDefinition$(
    id: string | number,
    baselineDefinition: BaselineDefinition | Partial<BaselineDefinition>,
  ): Observable<BaselineDefinition> {
    return this.resourceService.update('baseline-definitions', id, baselineDefinition).pipe(
      catchError((err) => {
        if (err === 'body: data.granularity_ms should be equal to one of the allowed values') {
          err = 'This granularity is not valid for this baseline definition. Please select another option.';
        }
        this.alertService.setError(err, 7000);
        throw err;
      }),
    );
  }

  deleteBaselineDefinition$(baselineDefinitionId: string): Observable<BaselineDefinition> {
    return this.resourceService.delete('baseline-definitions', baselineDefinitionId).pipe(
      catchError((err) => {
        this.alertService.setError(err, 7000);
        throw err;
      }),
    );
  }

  seedProducts(currentNode: any, matching: any[]) {
      if(currentNode.children) {
          currentNode.children.forEach((child: any) => {
              return this.seedProducts(child, matching);
          })
      }
      else if(currentNode.drType == "Product") {
          const seededProduct = matching.find(product => product.id === currentNode.id);
          currentNode.children = seededProduct.children;
          return currentNode;
      }
  }
}
