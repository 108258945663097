import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss'],
})
export class InlineEditComponent<T> implements OnInit, OnChanges {
  shouldUpdate$ = new Subject<boolean>();
  @Input() htmlTag: 'h1' | 'span' | 'div';
  @Input() inputType: 'input' | 'checkbox' | 'textarea' | 'select';
  @Input() formControl: UntypedFormControl;
  @Input() label: string;
  @Input() fieldToUpdate: string;
  @Input() validations: any;

  @Input() selectArray?: T[];
  @Input() selectDisplayField?: keyof T;
  @Input() selectValueField?: keyof T;
  @Input() selectIdField?: keyof T;
  @Input() required?: keyof T;

  @Input() callback: (formControl: UntypedFormControl, fieldToUpdate: string, shouldUpdate$) => void;

  @Input() canEdit?: boolean;

  @Output() formControlChange = new EventEmitter<UntypedFormControl>();

  isEditing = false;
  innerHtml: string;

  ngOnInit(): void {
    this.refreshInnerHtml(this.selectDisplayValue(this.formControl.value));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.refreshInnerHtml(this.selectDisplayValue(this.formControl.value));
    if (!this.canEdit) {
      this.formControl.disable();
    }
  }

  editingEnd(label: string): void {
    if (!this.formControl.valid) return;
    if (!this.formControl.dirty) {
      this.escWithoutSaving();
      return;
    }

    this.callback(this.formControl, this.fieldToUpdate, this.shouldUpdate$);
    this.shouldUpdate$.subscribe(() => {
      this.formControlChange.emit(this.formControl);
      this.refreshInnerHtml(label);
      this.isEditing = false;
    });
  }

  editingEndCheckbox() {
    this.callback(this.formControl, this.fieldToUpdate, this.shouldUpdate$);
  }

  escWithoutSaving(): void {
    this.isEditing = false;
  }

  refreshInnerHtml(label: string): void {
    this.innerHtml = `<${this.htmlTag}>${label}</${this.htmlTag}>`;
  }

  selectDisplayValue(value: string): string {
    if (this.inputType === 'select') {
      const t = this.selectArray.find((c) => c[this.selectValueField.valueOf()] === value);
      if (t) {
        return t[this.selectDisplayField.valueOf()];
      }
      return value;
    } else {
      return value;
    }
  }

  isBoolean() {
    return this.inputType === 'checkbox';
  }

  hasValidations() {
    return this.validations && this.validations.length > 0;
  }

  getValidation(type: string) {
    const foundValidation = this.validations.find((validation) => validation.operator === type);
    return foundValidation.value || '';
  }
}
