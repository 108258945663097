import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { SidenavService } from '../shared/services/sidenav.service';
import { BaselineDefinitionsService } from '../shared/services/baseline-definitions.service';
import { Product } from '@model';
import { BaselineDefinition } from '@model';
import { LoadingService } from '../shared/services/loading.service';
import {UserService} from "../shared/services/user.service";
import {NodeDefinition, TreeNode} from "enel-tree";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  loading = true;
  hierarchyLoading = true;
  noBaselines = false;
  dataSource: any[] = [];
  selectedBaselineId = '';
  isInit = true;
  products: Product[];
  baselineDefinitions: BaselineDefinition[];
  flattenedDataSource: any[] = [];
  disabledNodeAttribute = 'drType';
  disabledNodeValues: string[] = ['Operator', 'Program', 'Product'];
  disabledNodeDefinitions: NodeDefinition = { [this.disabledNodeAttribute]: this.disabledNodeValues };
  labelIdentifier = 'displayLabel';
  typeIdentifier = 'drType';
  ICON_DEFINITION: any = {
    Operator: 'ADMIN',
    Program: 'PATH',
    Product: 'PLANS',
    Baseline: 'GRAPH'
  };
  selectedNodes = new Set<TreeNode<any>>();
  selectedNode = '';

  constructor(
    private router: Router,
    private baselineService: BaselineDefinitionsService,
    private sidenavService: SidenavService,
    private loadingService: LoadingService,
    public userService: UserService,
    private cdr: ChangeDetectorRef,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIcon('ADMIN', sanitizer.bypassSecurityTrustResourceUrl('/assets/svgs/admin.svg'));
    iconRegistry.addSvgIcon('PATH', sanitizer.bypassSecurityTrustResourceUrl('/assets/svgs/path.svg'));
    iconRegistry.addSvgIcon('PLANS', sanitizer.bypassSecurityTrustResourceUrl('/assets/svgs/plans.svg'));
    iconRegistry.addSvgIcon('GRAPH', sanitizer.bypassSecurityTrustResourceUrl('/assets/svgs/graph.svg'));
  }

  async ngOnInit() {
    this.baselineService.baselineDefinitions$.subscribe(baselines => {
      if (baselines) {
        this.baselineDefinitions = baselines;
        this.noBaselines = baselines.length === 0;
      } else {
        return;
      }
    });

    this.baselineService.baselineHierarchy$.subscribe(hierarchy => {
      if(hierarchy && hierarchy.length > 0) {
        this.hierarchyLoading = true;
        this.flattenedDataSource = this.flattenTree(hierarchy);
        this.dataSource = hierarchy;
        if(this.selectedBaselineId && this.loading) {
          const selectedBaseline = this.getNodeById(this.selectedBaselineId);
          if(!this.selectedNodes.has(selectedBaseline)) {
            if(selectedBaseline && hierarchy.length > 0) {
              this.selectedNodes = new Set([selectedBaseline]);
            }
          }
        }
        else if(this.selectedBaselineId && !this.loading) {
          this.selectedNode = this.selectedBaselineId;
        }
        this.loading = false;
        this.refreshTree();
        this.hierarchyLoading = false;
      }
      else {
        return;
      }
    });

    this.baselineService.selectedBaselineDefinition.subscribe(
        selectedBaselineId => {
          this.selectedBaselineId = selectedBaselineId;
          if(this.dataSource.length > 0 && this.getNodeById(selectedBaselineId)) {
            this.selectedNode = this.selectedBaselineId;
            this.refreshTree();
          }
        },
    );
    this.baselineService.getBaselineDefinitions$();
  }

  onSelect(selectedNodes: any) {
    const id = selectedNodes[0].id;
    if(id != this.selectedBaselineId && !this.hierarchyLoading) {
      this.sidenavService.closeIfMobile();
      this.loadingService.setPageLoading(true);
      this.router.navigate([`details/${id}/view-baseline-definition`], {});
    }
  }

  routeToCreatePage() {
    this.sidenavService.closeIfMobile();
    this.loadingService.setPageLoading(true);
    this.router.navigate([`create`,{ selectedId: this.selectedBaselineId }]);
  }

  createExclusion(): void {
    console.log("creating exclusion");
  }

  refreshTree() {
    window.dispatchEvent(new Event('resize'));
    this.cdr.detectChanges();
  }

  private flattenTree(tree: TreeNode<any>[]): TreeNode<any>[] {
    // reset the internal structures
    const flattenedTree: TreeNode<any>[] = [];
    // terminal condition tracker
    const unflattenedNodes: TreeNode<any>[] = [...tree];
    // temporary current node
    let node: TreeNode<any> | undefined;

    // iterate until all nodes have been looked at
    while (unflattenedNodes.length > 0) {
      node = unflattenedNodes.pop();
      if (node === undefined) continue;
      flattenedTree.push(node);
      if (node.children) {
        node.children.forEach((child: TreeNode<any>) => {
          child.parent = node.parent ? [...node.parent, node.id] : [node.id];
          unflattenedNodes.push(child);
        });
      }
    }
    return flattenedTree;
  }
  getNodeById(id: string): TreeNode<any> {
    return this.flattenedDataSource.find(node => {
      return node.id == id;
    });
  }
}
