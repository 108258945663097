import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { LocalesService } from '../shared/services/locales.service';
import { TimezonesService } from '../shared/services/timezones.service';
import { BaselineDefinition, BaselineTemplate, Product } from '@model';
import { debounceTime, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BaselineDefinitionsService } from '../shared/services/baseline-definitions.service';
import { ActivatedRoute } from '@angular/router';
import * as camelcase from 'camelcase';
import { ProductService } from '../shared/services/product.service';
import { ProgramsService } from '../shared/services/programs.service';
import { OperatorService } from '../shared/services/operators.service';
import { LoadingService } from '../shared/services/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../dialogs/delete/delete-dialog.component';
import { BaselineValidationsService } from '../shared/services/baseline-validations.service';
import { Exclusion } from '../shared/model/baseline-template';
import { UserService } from '../shared/services/user.service';
import { Permission } from '../shared/services/auth-guard.service';

@Component({
  selector: 'app-baseline-form',
  templateUrl: './baseline-definition.component.html',
  styleUrls: ['./baseline-definition.component.scss'],
})
export class BaselineDefinitionComponent implements OnInit, OnDestroy {
  readonly EDIT = 'edit';
  readonly CREATE = 'create';

  APPPREFIX = 'bsl';
  APPNAME = 'MANAGE_BASELINES';

  namePlaceholder = '';
  nameLabel = '';
  loadingBaselineDefinitions: any[] = [];
  subscriptions = [];
  baselineDefinition: BaselineDefinition;
  baselineTemplates: BaselineTemplate[];
  baselineDefinitionBLTemplateNameFormControl: UntypedFormControl;
  baselineDefinitionProductFormControl: UntypedFormControl;
  baselineDefinitionExclusionControl: UntypedFormControl;
  defaultIndControl: UntypedFormControl;
  baselineDefinitionLabelControl: UntypedFormControl;
  baselineTemplateVariables: any[] = [];
  baselineTemplateVariablesUnsorted: any[] = [];
  templateExclusionSets: Exclusion[] = [];
  baselineTemplateSectionKeys: string[];
  products: Product[] = [];
  exclusionSubject = new Subject<UntypedFormControl>();
  defaultIndSubject = new Subject<UntypedFormControl>();

  @Input() baselineForm: UntypedFormGroup;

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get displayLabel() {
    return this.baselineForm.get('displayLabel');
  }

  private _mode: string;

  constructor(
    public dialog: MatDialog,
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private baselineDefinitionsService: BaselineDefinitionsService,
    private translateService: TranslateService,
    private ngxCookieService: NgxCookieService,
    private productsService: ProductService,
    private programsService: ProgramsService,
    private operatorsService: OperatorService,
    private loadingService: LoadingService,
    private route: ActivatedRoute, // private baselineValidationsService: BaselineValidationsService,
    public userService: UserService,
  ) {
    this.nameLabel = this.translateService.instant('baseline.baseline_name');
    this.namePlaceholder = this.translateService.instant('baseline.placeholder.baseline_name');
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.data.subscribe((data) => {
        this.loadingService.setPageLoading(false);
        this.baselineDefinition = data.data[0];
        this.baselineTemplates = data.data[1];
        // this.products = data.data[2];

        // Default display label to template name
        if (!this.baselineDefinition.displayLabel) {
          this.baselineDefinition.displayLabel = this.baselineDefinition.blTemplateName;
        }

        this.updateVariables(this.baselineDefinition.blTemplateName);
        this.setUpForm();
      }),
      this.exclusionSubject.pipe(debounceTime(1000)).subscribe((exclusionControl: UntypedFormControl) => {
        this.handleEdit(exclusionControl, 'exclusionSetIds');
      }),
      this.defaultIndSubject.pipe(debounceTime(1000)).subscribe((defaultIndControl: UntypedFormControl) => {
        this.handleEdit(defaultIndControl, 'defaultInd');
      }),
      this.userService.permissions$.subscribe((permissions) => {
        if (permissions['MANAGE_BASELINES'].includes(Permission.update)) {
          this.baselineDefinitionExclusionControl.enable();
        } else {
          this.baselineDefinitionExclusionControl.disable();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  openDeleteDialog() {
    this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {
        id: this.baselineDefinition.id,
      },
    });
  }

  handleEdit = (
    formControl: UntypedFormControl,
    fieldToUpdate: keyof BaselineDefinition,
    shouldUpdate$?: Subject<boolean>,
  ): void => {
    this.baselineDefinition[fieldToUpdate.valueOf()] = formControl.value;
    this.loadingBaselineDefinitions[fieldToUpdate] = true;

    this.updateBaselineDefinition(shouldUpdate$, fieldToUpdate);
  };

  updateBaselineDefinition(shouldUpdate$?: Subject<boolean> | undefined, fieldToUpdate?: string | undefined): void {
    // check for nulls on unrequired fields.
    const foundNullUnrequiredField = this.baselineTemplateVariablesUnsorted.find((tempVar) => {
      return (
        (tempVar.name === fieldToUpdate &&
          tempVar.required === false &&
          this.baselineDefinition[fieldToUpdate] === '') ||
        this.baselineDefinition[fieldToUpdate] === null
      );
    });

    if (foundNullUnrequiredField) {
      delete this.baselineDefinition[fieldToUpdate];
    }
    this.baselineDefinitionsService
      .updateBaselineDefinition$(this.baselineDefinition.id, this.baselineDefinition)
      .subscribe({
        next: (baselineDefinition: BaselineDefinition) => {
          if (shouldUpdate$) {
            shouldUpdate$.next(true);
          }
          if (fieldToUpdate) {
            this.loadingBaselineDefinitions[fieldToUpdate] = false;
          }

          this.baselineDefinition = baselineDefinition;
          return baselineDefinition;
        },
        error: (e) => {
          this.loadingBaselineDefinitions[fieldToUpdate] = false;
        },
      });
  }

  private setUpForm(): void {
    this.baselineDefinitionProductFormControl = new UntypedFormControl(this.baselineDefinition.productId, [
      Validators.required,
    ]);
    this.baselineDefinitionBLTemplateNameFormControl = new UntypedFormControl(this.baselineDefinition.blTemplateName, [
      Validators.required,
    ]);

    this.baselineDefinitionExclusionControl = new UntypedFormControl(this.baselineDefinition.exclusionSetIds, []);

    this.defaultIndControl = new UntypedFormControl(this.baselineDefinition.defaultInd, []);
    this.baselineDefinitionLabelControl = new UntypedFormControl(this.baselineDefinition.displayLabel, [
      Validators.maxLength(60),
    ]);
  }

  updateVariables = (templateName: string): void => {
    const fieldControls = [];
    this.baselineTemplateVariables = [];
    const { templateParams, exclusionSets } = this.baselineTemplates?.find(
      (template) => template.templateName === templateName,
    );
    this.templateExclusionSets = exclusionSets;

    // should update the variable's template
    // int, boolean, enum, float
    for (const param of templateParams) {
      param.name = camelcase(param.name);
      const validationService = new BaselineValidationsService(param);
      const validations: ValidatorFn[] = validationService.getValidations();

      if (param.type.indexOf('enum') !== -1) {
        fieldControls[param.name] = new UntypedFormControl(this.baselineDefinition[param.name], []);
        const variableParams = {
          selectArray: param.enumValues,
          selectIdField: 'value',
          selectValueField: 'value',
          selectDisplayField: 'displayLabel',
          inputType: 'select',
          formControl: fieldControls[param.name],
          label: param.displayLabel,
          name: param.name,
          description: param.description,
          order: param.order,
          section: param.sections,
          required: param.required,
        };
        this.baselineTemplateVariables.push(variableParams);
      }

      if (param.type === 'int' || param.type === 'float') {
        fieldControls[param.name] = new UntypedFormControl(this.baselineDefinition[param.name], [...validations]);
        const variableParams = {
          inputType: 'number',
          formControl: fieldControls[param.name],
          label: param.displayLabel,
          name: param.name,
          description: param.description,
          order: param.order,
          section: param.sections,
          validations: param.validations,
          required: param.required,
        };
        this.baselineTemplateVariables.push(variableParams);
      }

      if (param.type === 'boolean') {
        // convert to boolean if string

        const boolVal = this.baselineDefinition[param.name] ? JSON.parse(this.baselineDefinition[param.name]) : false;

        this.baselineDefinition[param.name] = boolVal;
        fieldControls[param.name] = new UntypedFormControl(boolVal, []);
        const variableParams = {
          inputType: 'checkbox',
          formControl: fieldControls[param.name],
          label: param.displayLabel,
          name: param.name,
          description: param.description,
          order: param.order,
          section: param.sections,
          required: param.required,
        };
        this.baselineTemplateVariables.push(variableParams);
      }
    }

    this.baselineTemplateVariablesUnsorted = [...this.baselineTemplateVariables];
    this.baselineTemplateVariables = this.baselineTemplateVariables
      .sort((currentVar, nextVar) => currentVar.order - nextVar.order)
      .reduce((acc, param) => {
        acc[param.section] = acc[param.section] || [];
        acc[param.section].push(param);
        return acc;
      }, {});

    this.baselineTemplateSectionKeys = Object.keys(this.baselineTemplateVariables).sort(
      (currentVar: any, nextVar: any) =>
        this.baselineTemplateVariables[currentVar][0].order - this.baselineTemplateVariables[nextVar][0].order,
    );
  };

  handleExclusionChange(exclusionControl: UntypedFormControl) {
    this.baselineDefinition.exclusionSetIds = exclusionControl.value;
    this.exclusionSubject.next(exclusionControl);
  }

  handleDefaultCheckboxChange(defaultIndControl: UntypedFormControl) {
    this.baselineDefinition.defaultInd = defaultIndControl.value;
    this.defaultIndSubject.next(defaultIndControl);
  }

  getExclusionLabel(exclusionId) {
    return this.templateExclusionSets.find((exclusion) => exclusion.id === exclusionId)?.description || exclusionId;
  }

  isDefault() {
    return this.baselineDefinition.defaultInd;
  }

  protected readonly Validators = Validators;
}
