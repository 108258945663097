import { Injectable } from '@angular/core';
import { from, Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { Program } from '@model';
import { catchError, tap } from 'rxjs/operators';
import { ResourceService } from '../../services/resource.service';
import { TypesService } from './types.service';
import { GlobalAlertService } from './global-alert.service';

@Injectable()
export class ProgramsService {
  private readonly programEmitter = new Subject<Program>();
  readonly programs$ = new Subject<Program[]>();

  constructor(
    private apiService: ApiService,
    private resourceService: ResourceService<Program>,
    private typesService: TypesService,
    private alertService: GlobalAlertService,
  ) {}

  getProgram$(programId: number | string): Observable<Program> {
    return this.resourceService.getById('programs', programId).pipe(tap(program => this.programEmitter.next(program)));
  }

  getPrograms$(): Observable<Program[]> {
    const observingPrograms = from(this.typesService.getAllPagesForType('program')).pipe(
      catchError(err => {
        this.alertService.setError(err, 7000);
        throw err;
      }),
    );
    observingPrograms.subscribe(programs => this.programs$.next(programs));
    return observingPrograms;
  }
}
