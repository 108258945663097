import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { BaselineDefinition, BaselineTemplate } from '@model';
import { BaselineDefinitionsService } from '../shared/services/baseline-definitions.service';
import { BaselineTemplatesService } from '../shared/services/baseline-templates.service';

@Injectable()
export class BaselineDefinitionResolver implements Resolve<[BaselineDefinition, BaselineTemplate[]]> {
  constructor(
    private baselineDefinitionService: BaselineDefinitionsService,
    private baselineTemplateService: BaselineTemplatesService, // private productService: ProductService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<[BaselineDefinition, BaselineTemplate[]]> {
    const baselineDefinitionId = route.paramMap.get('baselineDefinitionId');
    const templates$ = this.baselineTemplateService.getBaselineTemplates$();
    const baselineDefinition$ = this.baselineDefinitionService.getBaselineDefinition$(baselineDefinitionId);
    return forkJoin([baselineDefinition$, templates$]);
  }
}
