/* * * ANGULAR * * */
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CommonModule, registerLocaleData } from '@angular/common';

/* * * 3RD PARTY * * */
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/* * * SHARED * * */
import { GlobalFooterModule } from 'ngx-global-contact-footer';
import { GlobalNavModule } from 'ngx-global-nav';

import { NgxDeeplinkerModule } from 'ngx-deeplinker';
import { TreeSelectorModule } from 'ngx-tree-selector';
import {TreeModule} from "enel-tree";
import { AnalyticsService, CookieService as NgxCookieService, NOC_ANALYTICS } from 'ngx-shared-services';
import { analytics } from 'noc-analytics';
import { CookieService } from './shared/services/cookie.service';
import { GlobalAlertService } from './shared/services/global-alert.service';
import { GlobalAlertModule } from './global_alert/global-alert.module';
import { I18nService } from './shared/services/i18n.service';
import { TypesService } from './shared/services/types.service';

import { AuthHttpInterceptor } from './services/auth.interceptor';
import { MockHttpInterceptor } from './services/mock.interceptor';

/* * * COMPONENTS * * */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ListComponent } from './list/list.component';
import { BaselineDefinitionComponent } from './baseline-definition-details/baseline-definition.component';
import { DeleteDialogComponent } from './dialogs/delete/delete-dialog.component';
import { DefaultComponent } from './default/default.component';
import { RootResolver } from './default/root.resolver';

import { ApiService } from './shared/services/api.service';
import { DeeplinksService } from './shared/services/deeplinks.service';
import { LocalesService } from './shared/services/locales.service';
import { TimezonesService } from './shared/services/timezones.service';
import { SidenavService } from './shared/services/sidenav.service';
import { NavService } from './shared/services/global-nav.service';
import { BaselineDefinitionsService } from './shared/services/baseline-definitions.service';
import { ResourceService } from './services/resource.service';

import { InlineEditModule } from './inline-edit/inline-edit.module';

/* * * MATERIAL * * */
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';

import localeIt from '@angular/common/locales/it';
import localeZh from '@angular/common/locales/zh';
import localePl from '@angular/common/locales/pl';
import localeJa from '@angular/common/locales/ja';
import localeNb from '@angular/common/locales/nb';
import { BaselineDefinitionResolver } from './baseline-definition-details/baseline-definition.resolver';
import { BaselineTemplatesService } from './shared/services/baseline-templates.service';
import { CreateComponent } from './create/create.component';
import { CreateBaselineResolver } from './create/create-baseline.resolver';
import { ProductService } from './shared/services/product.service';
import { ProgramsService } from './shared/services/programs.service';
import { OperatorService } from './shared/services/operators.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LoadingService } from './shared/services/loading.service';
import { MatTooltipModule } from '@angular/material/tooltip';

registerLocaleData(localeIt, 'it-IT');
registerLocaleData(localeZh, 'zh-TW');
registerLocaleData(localePl, 'pl-PL');
registerLocaleData(localeJa, 'ja-JP');
registerLocaleData(localeNb, 'nb-NO');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DefaultComponent,
    ListComponent,
    BaselineDefinitionComponent,
    DeleteDialogComponent,
    CreateComponent,
  ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        GlobalNavModule.forRoot({namespace: 'ngx-global-nav.'}),
        GlobalFooterModule.forRoot({namespace: 'ngx-global-contact-footer.'}),
        GlobalAlertModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        RouterModule.forRoot([]),
        AppRoutingModule,
        InlineEditModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTabsModule,
        MatTableModule,
        MatInputModule,
        NgxDeeplinkerModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatIconModule,
        MatCheckboxModule,
        TreeSelectorModule,
        MatButtonModule,
        MatDialogModule,
        MatSidenavModule,
        ReactiveFormsModule,
        MatListModule,
        ScrollingModule,
        MatTooltipModule,
        TreeModule,
    ],
  providers: [
    TranslateService,
    AnalyticsService,
    NgxCookieService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: MockHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    HttpClient,
    ApiService,
    BaselineDefinitionsService,
    BaselineTemplatesService,
    BaselineDefinitionResolver,
    CreateBaselineResolver,
    GlobalAlertService,
    I18nService,
    TimezonesService,
    LocalesService,
    DeeplinksService,
    MatDatepickerModule,
    SidenavService,
    TypesService,
    ProductService,
    ProgramsService,
    OperatorService,
    LoadingService,
    NavService,
    RootResolver,
    ResourceService,
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: false,
        strict: true,
      },
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: NOC_ANALYTICS, useValue: analytics },
  ],
  bootstrap: [AppComponent],
  exports: [TranslateModule],
})
export class AppModule {}
